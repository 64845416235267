/* eslint-disable react/jsx-wrap-multilines */
import { Box, Divider, Fade, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { ArrowDropDown, Check, ExpandMore } from '@material-ui/icons';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import MenuItemDropDownItem from 'components-v2/atoms/MenuItemDropDown';
import { AvatarStatic } from 'components/atoms/ImageStatic';
import NewCustomModal from 'components/mocules/NewCustomModal';
import {
  BILL,
  FEEDBACK,
  ICON_PROFILE_CAMBODIA,
  ICON_PROFILE_CHANGE_LANGUAGE,
  ICON_PROFILE_ENGLISH,
  LOGOUT_ICON_IMG,
  ORDER,
  POINTS,
  QR,
  REFER,
  REWARDS,
  STATISTICAL,
  USER,
  VIEWED,
  VOUCHER,
  WISHLIST
} from 'constants/Icons';
import { AVATARV2_ICON_IMAGE } from 'constants/Images';
import { USER_PROMO_CODES_URL } from 'constants/Paths';
import { useAuth, useCart } from 'context';
import { useModal } from 'hooks';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { CURRENCY, ENV_COUNTRY } from 'sysconfig';
import { gtag } from 'utils';
import { formatFloatNumber } from 'utils/FormatNumber';
import { useStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import styles from './styles.module.css';

const locales = {
  EN: 'en',
  CAM: 'cam',
};
function ToggleProfile({ level, point, classCustom, isInNavbar = false, ...restProps }) {
  const { t: translateCommon } = useTranslation('common');
  const { t: translatePopupAction, i18n } = useTranslation('popup-action');
  const router = useRouter();
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const isMobile = restProps?.isMobile !== undefined ? restProps?.isMobile : isMobileV2;
  const validateFeature = useStore((state) => state.validateFeature);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPoupLogout, toggleLogout] = useModal(false);
  const { clearCart } = useCart();
  const { logout } = useAuth();
  const availableDebtBalanceFormated = useStore((state) => state.getAvailableDebtBalance({ isFormated: true, isCurrency: false }));
  const open = Boolean(anchorEl);
  const currentLanguage = i18n.language;

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleLogout = () => {
    logout(() => {}, { clearAll: true });
    clearCart();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = ({ lang = locales.CAM }) => {
    if (currentLanguage !== lang) {
      i18n.changeLanguage(lang);
      Cookies.set('NEXT_LOCALE', lang);
      // router.reload();
    }
  };

  const renderProfileIcon = () => {
    if (!isMobile && !isInNavbar) {
      return (
        <IconButton className={clsx(styles.iconInfo, classCustom)} onClick={handleClick} data-test="btn-toggle-profile">
          <ExpandMore />
        </IconButton>
      );
    }
    if (!isMobile && isInNavbar) {
      return (
        <Box className={styles.avatar_navbar} onClick={handleClick}>
          <AvatarStatic src={AVATARV2_ICON_IMAGE} width={100} className={styles.avatarIcon} />
          <ArrowDropDown height="24px" width="24px" style={{ color: 'black' }} />
        </Box>
      );
    }
    if (isMobile) {
      return (
        <Box className={styles.avatar} onClick={handleClick}>
          <AvatarStatic src={AVATARV2_ICON_IMAGE} width={100} className={styles.avatarIcon} />
        </Box>
      );
    }

    return <></>;
  };

  return (
    <Box {...restProps}>
      {renderProfileIcon()}
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        TransitionComponent={Fade}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: clsx(classCustom, !isMobile ? styles.Menu : styles.mobile_menu) }}
      >
        <MenuItem onClick={handleClose} className={styles.MenuItem}>
          <Box className={styles.MenuTop}>
            <Tooltip title={translateCommon('remaining_debt_limit')}>
              <Typography variant="h5" className={styles.MenuTop_title}>
                {translateCommon('limit')} ({CURRENCY})
              </Typography>
            </Tooltip>

            <Typography variant="h5" className={styles.MenuTop_title}>
              {translateCommon('tab_menu.points')}
            </Typography>
          </Box>
          <Box className={styles.MenuTop}>
            <Typography className={styles.MenuTop_titleTransaction} variant="h5" color="primary">
              {availableDebtBalanceFormated}
            </Typography>
            <Typography className={styles.MenuTop_titleTransaction} variant="h5" color="secondary">
              {formatFloatNumber(point)}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />

        <MenuItemDropDownItem
          className={styles.ProfileStyle}
          handleClick={() => router.push('/my-account')}
          text={translateCommon('tab_menu.my_account')}
        >
          <USER />
        </MenuItemDropDownItem>
        {/* {level !== 'LEVEL_BLACKLIST' && FEATURE_LEVEL && (
          <MenuItemDropDownItem handleClick={() => router.push('/user/level')} text="Thông tin cấp bậc">
            <LEVEL />
          </MenuItemDropDownItem>
        )} */}
        <MenuItemDropDownItem handleClick={() => router.push('/users/loyalty_points')} text={translateCommon('tab_menu.my_points')}>
          <POINTS />
        </MenuItemDropDownItem>
        <MenuItemDropDownItem handleClick={() => router.push('/my-order')} text={translateCommon('tab_menu.my_orders')}>
          <ORDER />
        </MenuItemDropDownItem>
        <MenuItemDropDownItem handleClick={() => router.push('/user/dashboard')} text={translateCommon('tab_menu.statistics')}>
          <STATISTICAL />
        </MenuItemDropDownItem>

        <MenuItemDropDownItem handleClick={() => router.push('/users/referrals')} text={translateCommon('tab_menu.refer_friends')}>
          <REFER />
        </MenuItemDropDownItem>

        <MenuItemDropDownItem handleClick={() => router.push(USER_PROMO_CODES_URL)} text={translateCommon('tab_menu.my_discount')}>
          <VOUCHER />
        </MenuItemDropDownItem>

        <MenuItemDropDownItem handleClick={() => router.push('/users/my-ticket')} text={translateCommon('tab_menu.my_feedback')}>
          <FEEDBACK />
        </MenuItemDropDownItem>
        {ENV_COUNTRY === 'vn' && (
          <MenuItemDropDownItem
            handleClick={() => {
              gtag.clickTrackingOrder();
              router.push('https://tracuu.thuocsi.vn');
            }}
            text={translateCommon('tab_menu.bill_of_lading_lookup')}
          >
            <BILL />
          </MenuItemDropDownItem>
        )}

        {ENV_COUNTRY === 'vn' && (
          <MenuItemDropDownItem handleClick={() => router.push('/qr')} text={translateCommon('tab_menu.qr_code_lookup')}>
            <QR />
          </MenuItemDropDownItem>
        )}

        <MenuItemDropDownItem handleClick={() => router.push('/user/wishlist')} text={translateCommon('tab_menu.interested_products')}>
          <WISHLIST />
        </MenuItemDropDownItem>

        <MenuItemDropDownItem text={translateCommon('tab_menu.viewed_products')} handleClick={() => router.push('/productviewed')}>
          <VIEWED />
        </MenuItemDropDownItem>
        <MenuItemDropDownItem text={translateCommon('tab_menu.reward_events')} handleClick={() => router.push('/users/rewards')}>
          <REWARDS />
        </MenuItemDropDownItem>
        {/* KH Vip mới thấy */}
        {validateFeature('BULK_ORDER') && (
          <MenuItemDropDownItem text={translateCommon('tab_menu.bulk_orders')} handleClick={() => router.push('/bulk-order/import')}>
            <BILL />
          </MenuItemDropDownItem>
        )}
        <Divider />
        <MenuItemDropDownItem text={translateCommon('change_language')}>
          <ICON_PROFILE_CHANGE_LANGUAGE />
        </MenuItemDropDownItem>
        <MenuItemDropDownItem
          text={
            <Grid container justifyContent="space-between">
              <Grid item> English </Grid>
              {currentLanguage === locales.EN && (
                <Grid item>
                  <Check color="primary" />
                </Grid>
              )}
            </Grid>
          }
          handleClick={() => handleChangeLanguage({ lang: locales.EN })}
        >
          <ICON_PROFILE_ENGLISH />
        </MenuItemDropDownItem>
        <MenuItemDropDownItem
          text={
            <Grid container justifyContent="space-between">
              <Grid item> ខ្មែរ </Grid>
              {currentLanguage === locales.CAM && (
                <Grid item>
                  <Check color="primary" />
                </Grid>
              )}
            </Grid>
          }
          handleClick={() => handleChangeLanguage({ lang: locales.CAM })}
        >
          <ICON_PROFILE_CAMBODIA />
        </MenuItemDropDownItem>
        <Divider />
        <MenuItemDropDownItem handleClick={toggleLogout} text={translateCommon('logout')}>
          <LOGOUT_ICON_IMG />
        </MenuItemDropDownItem>
      </Menu>
      <NewCustomModal
        visible={showPoupLogout}
        onClose={toggleLogout}
        title={translateCommon('confirmation')}
        content={translatePopupAction('popup-action:popup-logout.logout_right')}
        btnOk={translateCommon('yes')}
        onClickOk={handleLogout}
        btnOnClose={translateCommon('no')}
      />
    </Box>
  );
}

export default ToggleProfile;

/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box, FormControl, InputAdornment, Select, MenuItem, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { SEARCHV2ACTIVE_ICON, SEARCHV2_ICON } from 'constants/Images';
import { HOAT_CHAT, NHA_BAN_HANG, SEARCH_TYPE_LIST, THUOC, THUOC_VA_HOAT_CHAT } from 'constants/data';
import { useSearch } from 'context';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { memo, useEffect, useRef, useState } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import deviceUtils from 'utils/deviceUtils';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import SearchDropdown from '../SearchDropdown';
import styles from './styles.module.css';

// SEARCH PRODUCT
const SearchInput = memo(({ classCustom, scrollSticky = null, ...restProps }) => {
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const hashtagTopSearch = useStore((state) => state.hashtagTopSearch);
  const { t: translateCommon } = useTranslation('common');
  const {
    keyword,
    searchProduct,
    searchIngredient,
    searchSeller,
    clearSearchRes,
    handleBlur,
    handleSearchbox,
    handleFocus,
    searchType,
    isFocus,
    handleChangeTypeSearch,
    suggestKeywords,
    handleKeyDown,
  } = useSearch();
  const searchContainerRef = useRef(null);
  const searchDropDownRef = useRef(null);
  const inputEl = useRef(null);
  const suggestKeywordsRef = useRef(null);
  const suggestIndex = useRef(0);
  const [isVisibleSuggest, setIsVisibleSuggest] = useState(true);

  const handleShowFirstClick = () => {
    inputEl.current?.focus();
    document.body.style.overflow = 'hidden';
    if (!isMobileV2) {
      document.body.style.paddingRight = '6px';
    }

    if (keyword.length <= 0) {
      clearSearchRes();
    }
    handleFocus();
  };

  const handleClickOutside = () => {
    if (!isFocus) return;
    document.body.style.overflow = 'auto';
    if (!isMobileV2) {
      document.body.style.paddingRight = '0px';
    }
    setIsVisibleSuggest(true);
    handleBlur();
  };

  const checkStickySelect = () => {
    if (!scrollSticky) {
      if (isFocus) {
        return styles.focusSelect;
      }
      return null;
    }
    if (isFocus && scrollSticky) {
      return styles.stickySelect;
    }
    return styles.hiddenSelect;
  };

  const checkStickyInput = () => {
    if (!scrollSticky || isFocus || deviceUtils.isTablet()) {
      return null;
    }
    return styles.root_input_sticky;
  };

  const getDataSearch = () => {
    switch (searchType) {
      case THUOC_VA_HOAT_CHAT:
      case THUOC:
        return searchProduct;
      case HOAT_CHAT:
        return searchIngredient;
      case NHA_BAN_HANG:
        return searchSeller;
      default:
        return [];
    }
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      handleClickOutside();
      handleKeyDown(event);
    }
  };

  const handleReduceText = ({ text = '', length = 25 }) => {
    if (text?.length > length) {
      return `${text.slice(0, length)}...`;
    }
    return text;
  };

  useEffect(() => {
    if (!suggestKeywordsRef.current) return;
    if (!suggestKeywords && suggestKeywords?.length <= 1) return;
    if (keyword.length > 0) return;

    const suggestKeywordListElm = suggestKeywordsRef.current.getElementsByClassName('suggest_keyword');

    suggestKeywordListElm[0].style.opacity = '1';
    suggestKeywordListElm[0].style.transform = 'translateY(55%)';

    const interval = setInterval(() => {
      let nextIndex = suggestIndex.current + 1;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < suggestKeywords.length; i++) {
        if (i === suggestIndex.current) {
          suggestKeywordListElm[i].style.opacity = '1';
          suggestKeywordListElm[i].style.transform = 'translateY(55%)';
        } else {
          suggestKeywordListElm[i].style.opacity = '0';
          suggestKeywordListElm[i].style.transform = 'translateY(100%)';
        }
      }

      if (nextIndex >= suggestKeywords.length) {
        nextIndex = 0;
      }
      suggestIndex.current = nextIndex;
    }, 2500);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval);
    };
  }, [suggestKeywords, isVisibleSuggest, keyword]);

  useEffect(() => {
    if (!isVisibleSuggest) {
      handleShowFirstClick();
    }
  }, [isVisibleSuggest]);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e?.target && !searchContainerRef.current?.contains(e.target) && !searchDropDownRef.current?.contains(e.target) && isFocus) {
        handleClickOutside();
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isFocus]);

  if (deviceUtils.isTablet()) {
    return (
      <>
        <Box className={clsx(styles.search_wrap, classCustom, styles.search_ic_tablet)}>
          <div className={clsx(styles.searchTabletContainer, isFocus && styles.border_search_tablet)}>
            {!scrollSticky && (
              <>
                {/* Search input */}
                <TextField
                  className={styles.text_field_tablet}
                  inputRef={inputEl}
                  value={keyword}
                  classes={{
                    root: styles.root_input_tablet,
                  }}
                  async
                  onChange={(e) => handleSearchbox(e)}
                  onKeyDown={handleEnter}
                  onClick={() => {
                    setIsVisibleSuggest(false);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image src={isFocus ? SEARCHV2ACTIVE_ICON : SEARCHV2_ICON} height="20px" width="20px" layout="fixed" />
                      </InputAdornment>
                    ),
                    placeholder: suggestKeywords?.length > 0 ? '' : translateCommon('nav_bar.search_input_placeholder'),
                    autoComplete: 'off',
                  }}
                />
                {isVisibleSuggest && suggestKeywords?.length > 0 && (
                  <div id="suggest-search-keywords" className={styles.placeholder_wrapper} onClick={() => setIsVisibleSuggest(false)}>
                    {suggestKeywords.map((suggestKeyword, index) => (
                      <div key={suggestKeyword.id} className={clsx(styles.placeholder, 'suggest_keyword')}>
                        <span>{suggestKeyword.keyword}</span>
                      </div>
                    ))}
                  </div>
                )}
                {/* Dropdown menu */}
                <div className={styles.selectSearch}>
                  <FormControl className={styles.formControlTablet}>
                    <Select
                      className={clsx(styles.selectInput, styles.border_none)}
                      name="search"
                      id="search-type"
                      placeholder={translateCommon('nav_bar.search_input_placeholder_drug')}
                      IconComponent={ExpandMoreIcon}
                      onChange={(e) => handleChangeTypeSearch(e)}
                      value={SEARCH_TYPE_LIST.find((item) => item?.value === Number(searchType)).value || SEARCH_TYPE_LIST[0]?.value}
                      MenuProps={{
                        sx: {
                          '&& .Mui-selected': {
                            backgroundColor: '#00b46e',
                          },
                        },
                      }}
                    >
                      {SEARCH_TYPE_LIST.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value} className={styles.option}>
                          {translateCommon(`search_type.${item.value}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          </div>
          <SearchDropdown
            searchDropDownRef={searchDropDownRef}
            isMobile={isMobileV2}
            keyword={keyword}
            data={getDataSearch()}
            type={Number(searchType)}
            hashtagTopSearch={hashtagTopSearch}
            handleClickOutside={handleClickOutside}
          />
        </Box>
        {isFocus && <div className={styles.overlay} />}
      </>
    );
  }

  return (
    <>
      <Box className={clsx(styles.search_wrap, { [styles.mobile_search_wrap]: isMobileV2 }, classCustom)} ref={searchContainerRef}>
        <div
          className={clsx(styles.searchContainer, isFocus && styles.border_search_container, keyword?.length === 250 && styles.err_border)}
          data-test="search-container"
        >
          {/* Search input */}
          <TextField
            className={styles.root_input_scale}
            inputRef={inputEl}
            value={keyword}
            {...restProps}
            classes={{
              root: clsx(styles.root_input, { [styles.mobile_root_input]: isMobileV2 }, checkStickyInput()),
            }}
            async
            onChange={(e) => handleSearchbox(e)}
            onKeyDown={handleEnter}
            onClick={() => {
              setIsVisibleSuggest(false);
            }}
            inputProps={{
              maxLength: 250,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ImageFallbackStatic
                    src={isFocus ? SEARCHV2ACTIVE_ICON : SEARCHV2_ICON}
                    height={!isMobileV2 ? '20px' : '16px'}
                    width={!isMobileV2 ? '20px' : '16px'}
                  />
                </InputAdornment>
              ),
              placeholder: suggestKeywords?.length > 0 ? '' : translateCommon('nav_bar.search_input_placeholder'),
              autoComplete: 'off',
              type: 'search',
            }}
            data-test="search-input"
          />
          {isVisibleSuggest && suggestKeywords?.length > 0 && keyword?.trim()?.length === 0 && (
            <div ref={suggestKeywordsRef} className={styles.placeholder_wrapper} onClick={() => setIsVisibleSuggest(false)}>
              {suggestKeywords.map((suggestKeyword, index) => (
                <div key={suggestKeyword.id} className={clsx(styles.placeholder, 'suggest_keyword')}>
                  <span>{handleReduceText({ text: suggestKeyword?.keyword || '', length: 25 })}</span>
                </div>
              ))}
            </div>
          )}
          {/* Dropdown menu */}
          {!isMobileV2 && (
            <div className={clsx(styles.selectSearch, checkStickySelect())}>
              <FormControl className={styles.formControl}>
                <Select
                  className={clsx(styles.selectInput, styles.border_none)}
                  name="search"
                  id="search-type"
                  IconComponent={ExpandMoreIcon}
                  onChange={(e) => handleChangeTypeSearch(e)}
                  value={SEARCH_TYPE_LIST.find((item) => item?.value === Number(searchType))?.value || SEARCH_TYPE_LIST[0]?.value}
                  data-test="search-select-option"
                >
                  {SEARCH_TYPE_LIST.filter(Boolean).map((item) => (
                    <MenuItem key={uuidv4()} value={item.value} className={styles.option}>
                      {translateCommon(`search_type.${item.value}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
        <SearchDropdown
          searchDropDownRef={searchDropDownRef}
          isMobile={isMobileV2}
          keyword={keyword}
          data={getDataSearch()}
          type={Number(searchType)}
          hashtagTopSearch={hashtagTopSearch}
          handleClickOutside={handleClickOutside}
        />
      </Box>
      {isFocus && <div className={styles.overlay} />}
    </>
  );
});

export default SearchInput;

/* eslint-disable react/jsx-boolean-value */
import { Badge, Box, Container, Divider, Fade, Grid, IconButton, Menu, Tooltip, Typography } from '@material-ui/core';
import { Done, WatchLater } from '@material-ui/icons';
import clsx from 'clsx';
import Button from 'components-v2/atoms/ButtonV2';
import SearchInput from 'components-v2/mocules/SearchInput';
import ToggleProfile from 'components-v2/mocules/ToggleProfile';
import { LinkComp } from 'components/atoms';
import { AvatarStatic } from 'components/atoms/ImageStatic';
import { ENUM_TRACKING_ACTION } from 'constants/Enums';
import { AVATARV2_ICON_IMAGE, BRAND_LOGO_SVG, BRAND_LOGO_WHITE_SVG, CAREERV2_ICON, HOMEV2_ICON, NEWSV2_ICON, PHAN_HOI_ICON } from 'constants/Images';
import { IC_HEADER_CART, IC_HEADER_EDITOR, IC_HEADER_NOTIFICATION, IC_HEADER_USER } from 'constants/Images/header';
import { CART_URL, MY_ACCOUNT, PATH_CAREER, PATH_NEWS } from 'constants/Paths';
import { useAuth, useCart, useNotify, useSearch } from 'context';
import { useTranslation } from 'next-i18next';
import React, { memo, useEffect, useState } from 'react';
import { DOMAIN_FEEDBACK, DOMAIN_SELLER_CENTER, ENV_COUNTRY } from 'sysconfig';
import { DateTimeUtils, Tracking, gtag } from 'utils';
import { getHostName } from 'utils/HostNameUtils';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand-lib/storeGlobal';
import CountDownBar from './CountDownBar';
import styles from './styles.module.css';

const Logo = memo(({ isMobile = false }) => (
  <LinkComp href={getHostName()} prefetch id="logo-thuocsi" style={{ marginRight: !isMobile ? '10px' : '0px', padding: '5px 0px' }}>
    {ENV_COUNTRY !== 'vn' ? (
      <Box className={clsx(!isMobile ? styles.logo : styles.mobile_logo)}>
        <ImageFallbackStatic href="/" src={BRAND_LOGO_WHITE_SVG} width={!isMobile ? '118px' : '100px'} height={!isMobile ? '25px' : '21px'} />
      </Box>
    ) : (
      <Box className={clsx(!isMobile ? styles.logo : styles.mobile_logo)}>
        <ImageFallbackStatic
          id="logo-thuocsi"
          href={getHostName()}
          src={BRAND_LOGO_SVG}
          width={!isMobile ? '118px' : '100px'}
          height={!isMobile ? '25px' : '21px'}
          quality={100}
          layout="fixed"
        />
      </Box>
    )}
  </LinkComp>
));

const SearchInputHeader = memo(() => <SearchInput className={styles.SearchInput} />);

const HeadericonRight = memo((props) => {
  const { t: translateCommon } = useTranslation('common');
  const { isMobile } = props;
  const { notification, unread: unreadNotification, markAll, markReadByCode } = useNotify();
  const { totalQuantity } = useCart();
  const { reloadDataCustomer } = useAuth();
  const user = useStore((state) => state.user);
  const { accountID: accountId = '', customerID = '' } = user || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
    reloadDataCustomer();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // TRACKING CART
  const handleTrackingCartPage = () => {
    const { href } = window.location;
    Tracking.trackingFunc(ENUM_TRACKING_ACTION.CLICK_GO_TO_CART_PAGE, {
      accountId,
      customerID,
      page: '/cart',
      currentPage: href,
      isMobile,
    });
  };

  return (
    <Box className={styles.headerIconLogged}>
      <Box className={styles.headerStatus}>
        <Menu
          id="simple-menu"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          getContentAnchorEl={null}
          TransitionComponent={Fade}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ paper: styles.notifyWrap }}
        >
          <Box className={styles.notifyWrap}>
            <Box className={styles.notifyContentTop}>
              <Box>
                <Typography className={styles.notifyTitle} variant="h5">
                  {translateCommon('notification.title')}
                </Typography>
                {unreadNotification === 0 && (
                  <Typography className={styles.notifyStatus}>{translateCommon('notification.viewed_not_nofication')}</Typography>
                )}
              </Box>
              {notification.length !== 0 && (
                <Box>
                  <IconButton className={styles.markAll} onClick={() => markAll()}>
                    <Done />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Divider />
            {notification.length > 0 &&
              notification?.map((item) => (
                <LinkComp
                  key={uuidv4()}
                  onClick={() => markReadByCode(item.code)}
                  href={item.link}
                  className={item.isRead ? clsx(styles.notificationsItem, styles.read) : clsx(styles.notificationsItem, styles.unRead)}
                >
                  <Box className={styles.notifyIcon}>
                    <i className={`icomoon icon-loyalty + ${styles.icon}`} />
                  </Box>
                  <Box className={styles.notifyContent}>
                    <Box className={styles.notifyContentTitle}>{item.title}</Box>
                    <Box className={styles.notifyContentDescription}>{item.description}</Box>
                    <Typography className={styles.createdAt}>
                      <WatchLater style={{ marginRight: '4px' }} />
                      {DateTimeUtils.getTimeAgo(item.createdTime, translateCommon)}
                    </Typography>
                  </Box>
                  <Divider />
                </LinkComp>
              ))}
            {notification.length > 0 && (
              <Box style={{ padding: '8px' }}>
                <LinkComp className={styles.viewAll} href="/notifications" prefetch>
                  <Typography display="inline" className={styles.viewAllTitle}>
                    {translateCommon('view_all')}
                  </Typography>
                </LinkComp>
              </Box>
            )}
          </Box>
        </Menu>
        <Tooltip title={translateCommon('notification.title')} arrow>
          <Badge
            color="secondary"
            badgeContent={unreadNotification}
            invisible={unreadNotification === 0}
            className={styles.badge}
            overlap="rectangular"
          >
            <Box className={styles.notice} onClick={handleClick}>
              <ImageFallbackStatic src={IC_HEADER_NOTIFICATION} layout="fixed" width="21px" height="23px" />
            </Box>
          </Badge>
        </Tooltip>
        <LinkComp href={CART_URL} className={styles.cartLink} onClick={handleTrackingCartPage}>
          <Tooltip title={translateCommon('header.cart')} arrow>
            <Badge
              badgeContent={totalQuantity}
              max={1000}
              // invisible={totalQuantity === 0}
              color="secondary"
              overlap="rectangular"
              data-test="cart-num"
            >
              <Box className={styles.cart} data-test="cart-link">
                <ImageFallbackStatic src={IC_HEADER_CART} layout="fixed" width="22px" height="25px" />
              </Box>
            </Badge>
          </Tooltip>
        </LinkComp>
      </Box>
      {!isMobile ? (
        <Box className={styles.profile}>
          <LinkComp href={MY_ACCOUNT}>
            <Tooltip title={translateCommon('header.account_info')} arrow>
              <Box className={styles.avatar}>
                <AvatarStatic src={AVATARV2_ICON_IMAGE} width={100} className={styles.avatarIcon} />
              </Box>
            </Tooltip>
          </LinkComp>
          {/* name of avatar when logged into web */}
          <Box className={styles.profile_name}>
            <Typography>{props.user?.name || ''}</Typography>
          </Box>
          <ToggleProfile point={props.point} level={props.level} isMobile={isMobile} />
        </Box>
      ) : (
        <ToggleProfile point={props.point} level={props.level} isMobile={isMobile} />
      )}
    </Box>
  );
});

const HeaderTop = memo(({ isAuthenticated, t }) => (
  <Box className={styles.headerTop}>
    <Container className={styles.headerTop_wrapper} style={{ maxWidth: '1352px' }}>
      <Box className={styles.headerTopLink}>
        <LinkComp
          name={t('header.customer_service')}
          href={isAuthenticated ? '/users/my-ticket' : DOMAIN_FEEDBACK}
          target
          onClick={() => gtag.viewBlog()}
          className={styles.headerTopLink_content}
        >
          <ImageFallbackStatic src={PHAN_HOI_ICON} layout="fixed" width="16px" height="16px" />
          {/* <ContactSupport fontSize="small" style={{ fontSize: ' 16px', color: '#212b8d' }} /> */}
        </LinkComp>
      </Box>

      <Box className={styles.headerTopLink}>
        <LinkComp name={t('header.news')} href={PATH_NEWS} target onClick={() => gtag.viewBlog()} className={styles.headerTopLink_content}>
          <ImageFallbackStatic src={NEWSV2_ICON} layout="fixed" width="12px" height="10px" />
        </LinkComp>
      </Box>

      <Box className={styles.headerTopLink}>
        <LinkComp name={t('header.career')} href={PATH_CAREER} target onClick={() => gtag.viewCareer()} className={styles.headerTopLink_content}>
          <ImageFallbackStatic src={CAREERV2_ICON} layout="fixed" width="14px" height="10px" />
        </LinkComp>
      </Box>

      <Box className={styles.headerTopLink}>
        <LinkComp
          name={t('header.seller_center')}
          href={DOMAIN_SELLER_CENTER}
          target
          onClick={() => gtag.viewSeller()}
          className={clsx(styles.headerTopLink_content, styles.iconTopSeller)}
        >
          <ImageFallbackStatic src={HOMEV2_ICON} layout="fixed" width="11px" height="11px" />
        </LinkComp>
      </Box>
    </Container>
  </Box>
));

const Header = memo(({ balance = 0, isMobile }) => {
  const { user, isAuthenticated, toggleLogin, toggleSignUp } = useAuth();
  const [scrollSticky, setScrollSticky] = useState(false);
  const { t: translateCommon } = useTranslation('common');
  const { isFocus } = useSearch();

  useEffect(() => {
    const scrollChangeSticky = () => {
      // TODO: do có banner của insider nên + thêm 50
      if (window.scrollY >= 150) {
        setScrollSticky(true);
      } else {
        setScrollSticky(false);
      }
    };
    window.addEventListener('scroll', scrollChangeSticky);
    return () => window.removeEventListener('scroll', scrollChangeSticky);
  }, []);

  return (
    <>
      {!isMobile ? (
        <Box className={styles.headerCenter}>
          <CountDownBar />
          <Container className={styles.headerCenter_wrapper} style={{ maxWidth: '1328px' }}>
            <Logo />
            {!isAuthenticated ? (
              <Box className={styles.headerCenter_Status}>
                <Button
                  data-test="btn-signup"
                  className={styles.buttonStyle}
                  onClick={toggleSignUp}
                  startIcon={<ImageFallbackStatic src={IC_HEADER_EDITOR} layout="fixed" width="20px" height="20px" />}
                  hoverColor="#0cba69"
                  disableRipple
                >
                  {translateCommon('header.signup')}
                </Button>
                <Button
                  data-test="btn-signin"
                  className={styles.buttonStyle}
                  onClick={toggleLogin}
                  startIcon={<ImageFallbackStatic src={IC_HEADER_USER} layout="fixed" width="20px" height="20px" />}
                  hoverColor="#0cba69"
                  disableRipple
                >
                  {translateCommon('header.login')}
                </Button>
              </Box>
            ) : (
              <Grid container className={clsx(styles.mobile_header, scrollSticky && styles.sticky)}>
                <Grid item xs={7}>
                  {!scrollSticky && <SearchInputHeader />}
                </Grid>
                <Grid item xs={5}>
                  <HeadericonRight balance={balance} point={user?.point} level={user?.level} user={user} isMobile={isMobile} />
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
      ) : (
        <Box className={clsx(styles.headerCenter, styles.mobile_header, scrollSticky && styles.sticky)}>
          <Container className={styles.mobile_headerCenter_wrapper} style={{ maxWidth: '1328px' }}>
            {!isAuthenticated ? (
              <>
                <Logo isMobile />
                <Box className={styles.headerCenter_Status}>
                  <Button
                    data-test="btn-signup"
                    className={styles.buttonStyle}
                    onClick={toggleSignUp}
                    startIcon={<ImageFallbackStatic src={IC_HEADER_EDITOR} layout="fixed" width="20px" height="20px" />}
                    hoverColor="#0cba69"
                    disableRipple
                  >
                    {translateCommon('header.signup')}
                  </Button>
                  <Button
                    data-test="btn-signin"
                    className={styles.buttonStyle}
                    onClick={toggleLogin}
                    startIcon={<ImageFallbackStatic src={IC_HEADER_USER} layout="fixed" width="20px" height="20px" />}
                    hoverColor="#0cba69"
                    disableRipple
                  >
                    {translateCommon('header.login')}
                  </Button>
                </Box>
              </>
            ) : (
              <Grid container>
                <Grid item xs={isFocus ? 12 : 8}>
                  <SearchInputHeader />
                </Grid>
                <Grid item xs={4} style={{ display: isFocus ? 'none' : 'block' }}>
                  <HeadericonRight balance={balance} point={user?.point} level={user?.level} user={user} isMobile={isMobile} />
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
      )}
    </>
  );
});

export default Header;

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-wrap-multilines */
import { AppBar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import AlertSection from 'components-v2/atoms/AlertSection';
import Header from 'components-v2/layout/Header';
import { defaultOptionsTab } from 'components-v2/layout/Header/mobile/HeaderIconButton';
import Navbar from 'components-v2/layout/NavbarCam';
import NavbarMobile from 'components-v2/layout/NavbarMobileCam';
import FooterCam from 'components-v2/organisms/FooterCamNew';
import { ButtonHeader } from 'components/atoms/Button';
import HeadPage from 'components/atoms/HeadPage';
import VideoGuidePopup from 'components/mocules/VideoGuidePopup';
import { BRAND_NAME } from 'constants/Enums';
import { CART_URL, PATH_GUILE_HOW_TO_ORDER, PRODUCT_LIST, QUICK_ORDER } from 'constants/Paths';
import { useAuth, useCart } from 'context';
import { useModal } from 'hooks';
import useFirebase from 'hooks/useFirebase';
import useHandleTrial from 'hooks/useHandleTrial';
import useTimeout from 'hooks/useTimeout';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { ENV_COUNTRY, HOTLINE, NOTIFY_IN_TOP_BAR } from 'sysconfig';
import { CookiesParser, routeHandler } from 'utils';
import useMobileV2 from 'zustand-lib/storeMobile';
import FooterWithCart from '../FooterMobile/components/FooterWithCart';
import styles from './styles.module.css';

const DELAY_REQUEST_MODAL = 5000;

const Footer = dynamic(() => import('components-v2/organisms/Footer'), { ssr: false });
const CustomModal = dynamic(() => import('components/mocules/CustomModal'), { ssr: false });
const NewRegisterGuestModal = dynamic(() => import('components/organisms/NewRegisterGuestModal'), { ssr: false });
const NewSignInModal = dynamic(() => import('components/organisms/NewSignInModal'), { ssr: false });
const NewSignUpModal = dynamic(() => import('components/organisms/NewSignUpModal'), { ssr: false });
const NewChangePasswordModal = dynamic(() => import('components/organisms/NewChangePasswordModal'), { ssr: false });
const NewForgetPasswordModal = dynamic(() => import('components/organisms/NewForgetPasswordModal'), { ssr: false });
const FooterMobile = dynamic(() => import('../FooterMobile'), { ssr: false });
const HeaderMobile = dynamic(() => import('../HeaderMobile'), { ssr: false });
const HeaderMobileNew = dynamic(() => import('components-v2/layout/Header/mobile'), { ssr: false });
const FooterMobileNew = dynamic(() => import('components-v2/layout/Footer/mobile'), { ssr: false });

// modal
const RequestModal = dynamic(() => import('components/organisms/RequestModal'), { ssr: false });
const PopupFullScreen = dynamic(() => import('components-v2/PopupFullScreen'), { ssr: false });

const defaultMV2Options = {
  offHeader: false,
  title: '',
  CustomRightHeader: false, // => truyền vào components -> nếu có CustomRightHeader thì iconRightHeader sẽ ko hiển thị
  offIconRight: false,
  iconRightHeader: {
    // các icon right header muốn hiện (có sắp xếp theo thứ tự)
    notify: true,
    cart: true,
    userinfo: true,
    toQuickOrder: false,
    home: false,
  },
};

const mapFooter = ({ locale, isMobile }) => {
  switch (locale) {
    case 'cam': {
      return <FooterCam isMobile={isMobile} />;
    }
    default: {
      return <Footer />;
    }
  }
};
// this template is using for PC. mobile v1 and mobile
export default function Template({
  title = '',
  children,
  isMobile,
  pageName,
  pageTitle = '',
  product = '',
  point = 0,
  balance = 0,
  showTopSearchMV2 = false,
  overrideMV2Options = {},
  isWebView = false,
  hideNavbar = false,
  showMobileFooter = false,
}) {
  const {
    isAuthenticated,
    toggleLogin,
    isShowLogin,
    handleChangeForget,
    isShowForgetPassword,
    toggleForgetPassword,
    toggleChangePassword,
    isShowChangePassword,
    isShowSignUp,
    toggleSignUp,
    handleChangeSignIn,
    handleChangeSignUp,
    registerGuest,
    isShowRegisterGuest,
    toggleRegisterGuest,
    toggleShowGuestExpiredTime,
    isShowGuestExpiredTime,
    isShowForgotPassMess,
    toggleShowForgotPassMess,
    isShowBlockAccountMess,
    toggleShowBlockAccountMess,
    isShowNotFoundAccountMess,
    toggleShowNotFoundAccountMess,
    getValShowResponseNotFoundAccountMess,
    customerInfo,
    user,
  } = useAuth();

  const { t: translateCommon } = useTranslation('common');

  // const [openRequestModal, toggleRequestModal] = useModal();
  const toggleBeta = useMobileV2((state) => state.toggleBeta);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const beta = useMobileV2((state) => state.beta);

  const { clearCart, isShowModalMax200Item, toggleShowModalMax200Item } = useCart();

  const { pathname, asPath, ...restRouter } = useRouter();

  const [checkSupportNotification] = useFirebase(user);

  useHandleTrial(user, isMobile, toggleBeta, isMobileV2);

  // const openModal = () => {
  //   const IS_SHOW_REQUEST_MODAL = 'isShowRequestModal';
  //   const isShowRequestModal = CookiesParser.CookiesParser(document.cookie)[IS_SHOW_REQUEST_MODAL];
  //   if (!isAuthenticated && !isShowRequestModal && !isShowLogin && !isShowSignUp) {
  //     toggleRequestModal();
  //     document.cookie = `${IS_SHOW_REQUEST_MODAL}=true;`;
  //   }
  // };

  // useTimeout(openModal, DELAY_REQUEST_MODAL);

  let mv2Options = {};
  if (beta || pathname === '/conversations') {
    const isBottomNavigation = routeHandler.isBottomNavigation({ pathname, asPath, ...restRouter });
    if (!overrideMV2Options.iconRightHeader && !isBottomNavigation) {
      mv2Options = {
        ...mv2Options,
        ...defaultOptionsTab,
      };
    } else {
      mv2Options = {
        ...defaultMV2Options,
        ...overrideMV2Options,
      };
    }
    mv2Options = {
      ...mv2Options,
      title: overrideMV2Options?.title ? overrideMV2Options?.title : pageTitle,
    };
  }

  const checkDisplayVideoGuide = useCallback(() => { }, [])

  useEffect(() => {
    if (!isAuthenticated && isShowGuestExpiredTime) {
      clearCart();
    }
  }, [isShowGuestExpiredTime]);

  useEffect(() => {
    if (user) {
      checkSupportNotification();

      checkDisplayVideoGuide();
    }
  }, [user, isAuthenticated]);

  let notifyWidth = 0;
  if (NOTIFY_IN_TOP_BAR) {
    notifyWidth = isMobile ? 129 : 0;
  }

  const checkRenderUIFooter = () => {
    if (beta) {
      // show footer cart for quick order and cart page
      if (pathname === QUICK_ORDER || pathname === CART_URL) {
        return (
          <AppBar position="fixed" className={styles.appBar}>
            <FooterWithCart />
          </AppBar>
        );
      }

      if (routeHandler.isBottomNavigation({ pathname, asPath, ...restRouter })) {
        return (
          <div style={{ marginTop: '75px' }}>
            <FooterMobileNew
              translateCommon={translateCommon}
              isAuthenticated={isAuthenticated}
              toggleSignUp={toggleSignUp}
              toggleLogin={toggleLogin}
            />
          </div>
        );
      }
    }
    if (showMobileFooter) {
      return (
        <div style={{ marginTop: '75px' }}>
          <FooterMobileNew
            translateCommon={translateCommon}
            isAuthenticated={isAuthenticated}
            toggleSignUp={toggleSignUp}
            toggleLogin={toggleLogin}
          />
        </div>
      );
    }
    return <></>;
  };

  const MobileProdPage = [PRODUCT_LIST, '/manufacturers/[slug]', '/tag/[slug]'];
  const marginTopForHeader = () => {
    if (isMobile && beta && MobileProdPage.includes(pathname)) return '48px';
    if (isMobile && !mv2Options.offHeader) return '55px';

    return '0px';
  };

  return (
    <div className={styles.container}>
      <HeadPage title={title} />
      <div id="main" className={clsx(beta && styles.new_bg_mobile, isMobile && styles.bg_mobile)}>
        {!restRouter.query.isHideHeader && (
          <>
            <Header isMobile={isMobile} />
            {!isMobile && !hideNavbar && <Navbar pageName={pageName} point={point} balance={balance} />}
            {isMobile && !hideNavbar && !showMobileFooter && <NavbarMobile pageName={pageName} point={point} balance={balance} isMobile={isMobile} />}
          </>
        )}
        {/* Placeholder for NOTIFY_IN_TOP_BAR */}
        {pageName !== 'conversations' && (
          <AlertSection
            beta={beta}
            pathname={pathname}
            isMobile={isMobile}
            notifyWidth={notifyWidth}
            marginTopForHeader={marginTopForHeader}
            isAuthenticated={isAuthenticated}
            status={customerInfo?.status || ''}
            asPath={asPath}
            isMobileProdPage={beta && isMobile && MobileProdPage.includes(pathname)}
            isMobileV2={isMobileV2}
          />
        )}
        {children}
        {isMobile && isAuthenticated ? (
          <></>
        ) : (
          mapFooter({
            locale: ENV_COUNTRY,
            isMobile,
          })
        )}
        {isMobile && <>{checkRenderUIFooter()}</>}
        {isMobile && beta && !isAuthenticated ? (
          <PopupFullScreen user={user} />
        ) : (
          !isAuthenticated && (
            <>
              <NewSignInModal visible={isShowLogin} onClose={toggleLogin} onChangeForget={handleChangeForget} onChangeSignUp={handleChangeSignUp} />
              <NewForgetPasswordModal visible={isShowForgetPassword} onClose={toggleForgetPassword} />
              <NewSignUpModal visible={isShowSignUp} onClose={toggleSignUp} onChangeSignIn={handleChangeSignIn} className="signup-modal" />
              <NewRegisterGuestModal visible={isShowRegisterGuest} onClose={toggleRegisterGuest} onChangeRegisterGuest={registerGuest} />
              <CustomModal
                visible={isShowGuestExpiredTime}
                onClose={toggleShowGuestExpiredTime}
                content={translateCommon('message_time_out')}
                btnOkRender={false}
                btnCloseRender={false}
                customBtnRender={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className={styles.btngroup}>
                    <ButtonHeader variant="contained" btnType="primary" onClick={toggleSignUp}>
                      {translateCommon('create_an_account')}
                    </ButtonHeader>
                  </div>
                }
              />
              {isShowForgotPassMess && (
                <CustomModal
                  visible={isShowForgotPassMess}
                  onClose={toggleShowForgotPassMess}
                  content={translateCommon('message_wrong_pass').replace('{BRAND_NAME}', BRAND_NAME)}
                  btnOkRender={false}
                  btnCloseRender={false}
                  customBtnRender={
                    <div className={styles.btngroup}>
                      <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowForgotPassMess}>
                        {translateCommon('i_understand')}
                      </ButtonHeader>
                    </div>
                  }
                />
              )}
            </>
          )
        )}
        {isShowBlockAccountMess && (
          <CustomModal
            visible={isShowBlockAccountMess}
            onClose={toggleShowBlockAccountMess}
            content={
              <Typography className={styles.text_modal}>
                {translateCommon('message_block_account_1')} <a href={`tel:${HOTLINE}`}>{HOTLINE}</a> {translateCommon('message_block_account_2')}
              </Typography>
            }
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowBlockAccountMess}>
                  {translateCommon('i_understand')}
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isShowNotFoundAccountMess && (
          <CustomModal
            visible={isShowNotFoundAccountMess}
            onClose={toggleShowNotFoundAccountMess}
            content={`${translateCommon('message_not_found_account').replace('{BRAND_NAME}', BRAND_NAME)} ${JSON.stringify(
              getValShowResponseNotFoundAccountMess(),
            )}`}
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowNotFoundAccountMess}>
                  {translateCommon('i_understand')}
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isShowModalMax200Item && (
          <CustomModal
            visible={isShowModalMax200Item}
            onClose={toggleShowModalMax200Item}
            content={translateCommon('message_over_limit_200_item_in_cart')}
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowModalMax200Item}>
                  {translateCommon('i_understand')}
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isAuthenticated && isShowChangePassword && <NewChangePasswordModal visible={isShowChangePassword} onClose={toggleChangePassword} />}
        <VideoGuidePopup isAuthenticated={isAuthenticated} user={user} pageName={pageName} />
      </div>
      {/* mặc định nếu chưa đăng nhập thì mobile dùng fb  */}
      {/* TODO: FbMessenger  FEATURE-CHAT */}
      {/* {!isAuthenticated && isMobile && isShowDelay && FACEBOOK_MESSENGER_ID && <Facebook />} off su dung Chatthuocsi thay chat fb */}
      {/* nếu không phải mobile => web => check setting  */}
      {/* {!isMobile && false && <>{MapChatComponent[chatSetting]}</>} */}
      {/* {ENV_COUNTRY === 'vn' && !beta && openRequestModal && <RequestModal visible={openRequestModal} onClose={toggleRequestModal} />} */}
    </div>
  );
}
